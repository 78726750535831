<!-- 我的申报列表 查看详情 查看进度 领取反馈-->
<template>
  <div>
    <!-- <headtitle Otitle="我的申报"/>-->
    <el-card class="box-card">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }"
          ><a @click="request">首页</a></el-breadcrumb-item
        >
        <el-breadcrumb-item>申报管理</el-breadcrumb-item>
        <el-breadcrumb-item v-if="open">草稿箱</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="hr-10"></div>
      <el-button @click="goHome" v-show="isMobile">返回上一级</el-button>
      <div class="hr-10"></div>
      <el-table
        :data="tableData"
        stripe
        style="width: 100%"
        v-show="open === false"
      >
        <el-table-column label="标题" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-tag size="small" type="danger" v-if="!scope.row.policyTitle"
              >此申报对应政策表单已删除</el-tag
            >
            <span
              v-else
              @click="seeDateiled(scope.row)"
              style="cursor: pointer;"
              >{{ scope.row.policyTitle }}</span
            >
          </template>
        </el-table-column>
        <el-table-column prop="date" label="申报时间" width="180">
          <template slot-scope="scope">
            {{ scope.row.createTime | formatDate }}
          </template>
        </el-table-column>
        <el-table-column label="状态" width="180" align="center">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.currentStatus === 0">提交</el-tag>
            <el-tag type="success" v-if="scope.row.currentStatus === 11"
              >单位通过</el-tag
            >
            <el-tag type="warning" v-if="scope.row.currentStatus === 10"
              >单位驳回</el-tag
            >
            <el-tag type="warning" v-if="scope.row.currentStatus === 20"
              >管理员驳回</el-tag
            >
            <el-tag type="success" v-if="scope.row.currentStatus === 21"
              >管理员通过</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column label="操作" width="300">
          <template slot-scope="scope">
            <span v-if="scope.row.policyTitle">
              <!-- <el-button size="mini" plain @click="handleSbao(scope.$index, scope.row.id)">申报</el-button> -->
              <el-button
                size="mini"
                type="primary"
                plain
                @click="seedetailed(scope.row)"
              >
                查看详情
              </el-button>
              <el-button
                size="mini"
                type="primary"
                plain
                @click="handlePreview(scope.row)"
              >
                预览
              </el-button>

              <el-button
                size="mini"
                type="warning"
                plain
                @click="seespeed(scope.row)"
                >查看进度
              </el-button>
              <el-button
                v-if="
                  scope.row.currentStatus === 10 ||
                    scope.row.currentStatus === 20
                "
                size="mini"
                type="warning"
                plain
                @click="ckbh(scope.row)"
                >驳回查看
              </el-button>
              <span
                v-if="scope.row.currentStatus === 21"
                style="padding: 0 8px;"
              >
                <el-button
                  size="mini"
                  type="primary"
                  v-if="scope.row.complete === 0"
                  @click="feedback(scope.row)"
                  >领取反馈</el-button
                >
                <el-button
                  v-else
                  type="success"
                  @click="lookopen(scope.row)"
                  plain
                  size="mini"
                  >查看反馈</el-button
                >
              </span>
            </span>
          </template>
        </el-table-column>
      </el-table>

      <div class="hr-10"></div>

      <el-table
        :data="newData"
        stripe
        style="width: 100%"
        v-show="open === true"
      >
        <el-table-column label="标题" show-overflow-tooltip>
          <template slot-scope="scope">
            <span @click="seeDateiled(scope.row)" style="cursor: pointer;">{{
              scope.row.policyTitle
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="date" label="申报时间" width="180">
          <template slot-scope="scope">
            {{ scope.row.createTime | formatDate }}
          </template>
        </el-table-column>
        <el-table-column label="状态" width="180" align="center">
          <template>
            <el-tag>未提交</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="300">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              plain
              @click="requestDraft(scope.row)"
              >填写</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 
            <el-button size="mini" class="fl" plain type="info" style="margin: 8px;" @click="open = !open;isOpen(1)" v-show="open === false">草稿箱</el-button>
            <el-button size="mini" class="fr" plain type="info" style="margin: 8px;" @click="open = !open;isOpen()" v-show="open === true">返  回</el-button>
             -->

      <el-pagination
        v-if="open === true"
        background
        layout="prev, pager, next"
        :total="total2"
        :page-size="pageSize"
        @current-change="requestPage"
        style="margin-top: 32px"
      ></el-pagination>
      <el-pagination
        v-else
        background
        layout="prev, pager, next"
        :total="total"
        :page-size="pageSize"
        @current-change="requestPage"
        style="margin-top: 32px"
      ></el-pagination>
    </el-card>

    <el-dialog
      title="申报进度"
      :visible.sync="dialogSpeed"
      width="60%"
      :before-close="handleClose"
    >
      <div>
        <el-steps space="40%" :active="requestStatus" align-center>
          <el-step title="提交申请"></el-step>
          <el-step
            :title="
              state == 20 ? '管理员驳回' : state == 10 ? '单位驳回' : '单位通过'
            "
          ></el-step>
          <el-step title="审核通过"></el-step>
        </el-steps>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogSpeed = false">关 闭</el-button>
      </span>
    </el-dialog>

    <el-dialog title="驳回理由" :visible.sync="dialogSpeed4" width="60%">
      <div>
        {{ bhly.content }}
      </div>
      <!-- <span slot="footer" class="dialog-footer">
                <el-button @click="dialogSpeed4 = false">取 消</el-button>
                <el-button type="primary" @click="dialogSpeed4 = false">确 定</el-button>
            </span> -->
    </el-dialog>

    <el-dialog
      title="反馈"
      :visible.sync="dialogfeedback"
      width="560px"
      :before-close="handleClose"
      :showClose="showClose"
    >
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="金额">
          <el-input v-model="form.money"></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            type="textarea"
            v-model="form.remarks"
            placeholder="“已领取5000元补贴，期待下次发放补贴，希望经开区越来越好！”"
          ></el-input>
        </el-form-item>
        <div
          style="color: red;font-size: 12px;position: absolute;left: 100px;bottom: 98px;"
        >
          * 反馈机会仅有一次，请耐心等待至打款金额到账后再反馈
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submitFeedback">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="反馈内容" :visible.sync="dialogOne" width="560px">
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="金额">
          <el-input v-model="form.money"></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            type="textarea"
            v-model="form.remarks"
            placeholder="“已领取5000元补贴，期待下次发放补贴，希望经开区越来越好！”"
          ></el-input>
        </el-form-item>
        <div style="margin-left: -220px">
          反馈时间: {{ form.createTime | formatDate }}
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogOne = !dialogOne">关 闭</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="PDF预览"
      v-loading="loading"
      element-loading-text="拼命加载中"
      :visible.sync="dialogPdf"
      :destroy-on-close="true"
      width="80%"
    >
      <iframe
        :src="PdfUrl"
        frameborder="0"
        id="ifra"
        v-if="PdfUrl"
        height="600"
        width="100%"
      ></iframe>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogPdf = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
//import headtitle from "@/components/ControlPanel/headtitle";
import configDate from "@/utils/config";
export default {
  components: {
    //headtitle
  },
  data() {
    return {
      isMobile: false,
      dialogSpeed: false,
      dialogfeedback: false,
      dialogOne: false,
      showClose: false,
      dialogPdf: false,
      loading: false,
      PdfUrl: "",
      bhly: "",
      open: false,
      dialogSpeed4: false,
      page: 1,
      page2: 1,
      userid: "",
      total: 0,
      total2: 0,
      ldsubmit: false,
      pageSize: 10,
      affairId: 0,
      requestStatus: 1,
      state: "",
      form: {
        money: "",
        remarks: "",
      },
      tableData: [],
      newData: [],
      affair: false,
      userId: "",
    };
  },
  created() {
    this.userid = sessionStorage.getItem("ID");
    this.isOpen(this.$route.query.open); //初始化数据
    if (this.$route.query.draft === true) {
      this.open = true;
    }
  },
  mounted() {
    if (this.commonFunction._isMobile()) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  },
  methods: {
    goHome() {
      this.$router.go(-1);
    },
    request() {
      let newPage = this.$router.resolve({
        name: "EntryStoma",
      });
      window.open(newPage.href, "_blank");
    },
    requestDraft(row) {
      this.$router.push({
        path: "/declarefrom/" + row.policyId + "&" + row.id,
      });
    },
    isOpen(n) {
      if (n) {
        this.open = true;
        this.requestAffair(1);
      } else {
        this.open = false;
        this.getDate(1);
      }
      if (n && this.$route.query.open) return;
      this.$router.replace({
        path: "/declarelist",
        query: { open: n },
      });
    },
    requestAffair(page) {
      this.$axios
        .get("/affair/drafts", {
          params: {
            userId: this.userid,
            current: page,
            size: 10,
          },
        })
        .then((response) => {
          if (response.data.status === 200) {
            this.newData = response.data.data.records;
            this.total2 = response.data.data.total;
            // for (let i = 0; i < response.data.data.records.length; i++) {
            //     let policyId = response.data.data.records[i].policyId;
            // console.log(policyId)
            // this.$axios.get("/policy?id=" + policyId).then(response => {
            //     if (response.data.status === 200) {
            //         for (let j = 0; j < this.newData.length; j++) {
            //             this.newData[j].policyTitle = response.data.data.title
            //             console.log(this.newData[j])
            //         }
            //     }
            // })
            // }
          }
        });
    },
    cancel() {
      this.dialogfeedback = false;
      this.form = {
        money: "",
        remarks: "",
      };
    },
    lookopen(row) {
      this.dialogOne = true;
      this.$axios.get("/affair?id=" + row.id).then((response) => {
        this.affair = response.data.data.id;
        this.userId = response.data.data.ownerId;

        this.$axios
          .get(
            "/affair/feedback?affairId=" +
              this.affair +
              "&userId=" +
              this.userId
          )
          .then((response) => {
            this.form.money = response.data.data.content.split("===备注：")[0];
            this.form.remarks = response.data.data.content.split(
              "===备注："
            )[1];
            this.form.createTime = response.data.data.createTime;
          });
      });
    },
    getDate(page) {
      const self = this;
      this.$axios
        .get("/affair/page", {
          params: {
            userId: this.userid,
            current: page,
            type: sessionStorage.getItem("Leix"),
            size: 10,
          },
        })
        .then(function(res) {
          self.tableData = res.data.data.records;
          self.total = res.data.data.total;
        })
        .catch(function(error) {});
    },
    //分页
    requestPage(value) {
      const self = this;
      this.page = value;
      this.getDate(value);
      return;
      this.$axios
        .get("/affair/page", {
          params: {
            userId: this.userid,
            current: value,
            type: sessionStorage.getItem("Leix"),
            size: 10,
          },
        })
        .then(function(res) {
          self.tableData = res.data.data.records;
          self.total = res.data.data.total;
        })
        .catch(function(error) {
          console.error(error);
        });
    },
    //分页
    requestPage2(value) {
      const self = this;
      this.page2 = value;
      this.requestAffair(value);
      return;
      this.$axios
        .get("/affair/page", {
          params: {
            userId: this.userid,
            current: value,
            type: sessionStorage.getItem("Leix"),
            size: 10,
          },
        })
        .then(function(res) {
          self.tableData = res.data.data.records;
          self.total = res.data.data.total;
        })
        .catch(function(error) {
          console.error(error);
        });
    },
    handleSbao(i, id) {
      this.$router.push({ path: "/declarefrom/" + id });
    },
    seedetailed(row) {
      //console.log(row)
      this.$router.push({
        path: "/mydeclarexq/" + row.policyId + ":" + row.id,
      });
    },
    handlePreview(row) {
      let self = this;
      //console.log(row)
      // console.log({
      //     affairId: row.id,
      //     talentId: row.ownerId,
      //     enterpriseId: row.enterpriseId
      // })
      this.loading = true;
      this.dialogPdf = true;
      this.PdfUrl = this.dialogPdf
        ? configDate.url +
          "/export/preview?affairId=" +
          row.id +
          "&talentId=" +
          row.ownerId +
          "&enterpriseId=" +
          row.enterpriseId
        : "";
      setTimeout(function() {
        self.loading = false;
      }, 3000);

      // var iWidth=600; //弹出窗口的宽度;
      // var iHeight=400; //弹出窗口的高度;
      // var iTop = (window.screen.availHeight-30-iHeight)/2; //获得窗口的垂直位置;
      // var iLeft = (window.screen.availWidth-10-iWidth)/2; //获得窗口的水平位置;
      // var w = 'height='+iHeight+',width='+iHeight+',top='+iTop+',left='+iLeft+',toolbar=no,menubar=no,scrollbars=no, resizable=no,location=no, status=no';
      // window.open(configDate.url+'/export/preview?affairId='+row.id+'&talentId='+row.ownerId+'&enterpriseId='+row.enterpriseId,'',w)
    },

    seeDateiled(row) {
      this.$router.push({ path: "/declareitem/declarewz/" + row.policyId });
    },

    // 查看进度
    seespeed(row) {
      this.dialogSpeed = true;
      this.$axios.get("/affair?id=" + row.id).then((response) => {
        this.state = response.data.data.currentStatus;
        if (this.state === 11) {
          this.requestStatus = 2;
        } else if (this.state === 21) {
          this.requestStatus = 3;
        } else if (this.state === 20) {
          this.requestStatus = 2;
        } else if (this.state === 10) {
          this.requestStatus = 2;
        } else {
          this.requestStatus = 1;
        }
      });
    },
    // 查看驳回
    ckbh(row) {
      this.$axios.get("/affair?id=" + row.id).then((res) => {
        console.log(res.data.data);
        this.$axios
          .get(
            "/affair/statuses?affairId=" +
              row.id +
              "&userId=" +
              res.data.data.ownerId
          )
          .then((response) => {
            this.bhly = response.data.data[response.data.data.length - 1];
            this.dialogSpeed4 = true;
          });
      });
    },
    feedback(row) {
      this.dialogfeedback = true;
      this.affairId = row.id;
    },
    submitFeedback() {
      if (this.ldsubmit) return;
      this.ldsubmit = true;
      setTimeout(() => {
        this.ldsubmit = false;
      }, 2000);
      const self = this;
      this.$axios
        .post("/affair/feedback", {
          ownerId: sessionStorage.getItem("ID"),
          affairId: this.affairId,
          content: this.form.money + "￥===备注：" + this.form.remarks,
        })
        .then(function(res) {
          if (res.data.status === 200) {
            self.dialogfeedback = false;
            self.$notify({
              title: "成功",
              message: res.data.data,
              type: "success",
            });
            self.form = {
              money: "",
              remarks: "",
            };
            self.getDate(self.page);
          } else {
            self.$root.warn("领取失败");
          }
        })
        .catch((error) => {
          self.dialogfeedback = false;
          console.error(error);
        });
    },

    handleClose(done) {
      this.dialogSpeed = false;
    },
  },
};
</script>

<style lang="less" scoped>
.speeditem {
  h4 {
    margin: 0;
  }

  p {
    margin: 3px;
  }
}
</style>
