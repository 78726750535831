import { render, staticRenderFns } from "./declarelist.vue?vue&type=template&id=c30d97ce&scoped=true&"
import script from "./declarelist.vue?vue&type=script&lang=js&"
export * from "./declarelist.vue?vue&type=script&lang=js&"
import style0 from "./declarelist.vue?vue&type=style&index=0&id=c30d97ce&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c30d97ce",
  null
  
)

export default component.exports